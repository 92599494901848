export const typeOptions = ['string', 'number', 'boolean'].map(type => ({ title: type, id: type }))
export const requiredOptions = [false, true].map(value => ({ title: value ? 'yes' : 'no', id: value }))

export default {
  displayName: null,
  identifier: null,
  type: null,
  isRequired: null,
  editable: false,
  colorboxUrl: null,
  embeddedApplicationId: null
}
