import CoreApi from '@/api/core'
const API_NAME = '/EmbeddedApplicationField'

export const create = async (record) => {
  try {
    const response = await CoreApi().post(API_NAME, JSON.stringify(record))
    if (response.status === 201) {
      return response.data
    }
  } catch (error) {
    console.error(error.message)
  }
}

export const update = async (record) => {
  try {
    const response = await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error(error.message)
  }
}

export const deleteRecord = async (id) => {
  try {
    return await CoreApi().delete(API_NAME + '/' + id)
  } catch (error) {
    console.error(error.message)
  }
}
