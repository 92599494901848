<template>
  <div class="field-table">
    <div class="field-table__head">
      <span v-for="(header, index) in headers" :key="index" class="field-table__head__title">
        {{ $t(header.title) }}
      </span>
    </div>

    <div class="field-table__body">
      <div class="field-table__body__row" v-for="(item, index) in localFields" :key="index">
        <span class="field-table__body__row__title">{{ item.displayName }}</span>
        <span class="field-table__body__row__title">{{ item.identifier }}</span>
        <Select :options="typeOptions" v-model="item.type" disable-form-group no-label disabled/>
        <Select :options="requiredOptions" v-model="item.isRequired" disable-form-group no-label disabled/>
        <Input v-model="item.colorboxUrl" disable-form-group no-label :disabled="!isEdited(index)"/>

        <div class="field-table__body__row__actions">
          <template v-if="!isEdited(index)">
            <ButtonTableAction icon="edit" @click.stop="handleEdit(index)"/>
            <ButtonTableAction icon="delete" @click.stop="handleRemove(index)"/>
          </template>

          <template v-else>
            <ButtonTableAction icon="check" @click.stop="handleSubmit(item, index)"/>
            <ButtonTableAction icon="cancel" @click.stop="handleCancel(index)"/>
          </template>
        </div>
      </div>
    </div>

    <ConfirmDeleteModal v-if="showModal" :embedded-application-id="id" @close="closeModal" @submit="remove"/>
  </div>
</template>

<script>
import Select from '@/components/form/select/Select'
import Input from '@/components/form/inputs/Input'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import ConfirmDeleteModal from '@/components/embeddedApplication/ConfirmDeleteModal'
import { requiredOptions, typeOptions } from '@/model/EmbeddedApplicationFieldModel'

export default {
  name: 'EmbeddedApplicationFieldTable',
  components: { ConfirmDeleteModal, ButtonTableAction, Input, Select },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    },
    fields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { title: 'embeddedApplication.field.title' },
        { title: 'embeddedApplication.field.titleCP' },
        { title: 'embeddedApplication.field.type' },
        { title: 'embeddedApplication.field.required' },
        { title: 'embeddedApplication.field.colorboxUrl' },
        { title: 'embeddedApplication.actions' }
      ],
      editedItemsIndexes: [],
      showModal: false,
      articleList: [],
      indexToDelete: null
    }
  },
  computed: {
    typeOptions () {
      return typeOptions
    },
    requiredOptions () {
      return requiredOptions.map(item => ({ ...item, title: this.$t(item.title) }))
    },
    localFields: {
      get () {
        return this.fields.map(item => ({ ...item }))
      },
      set (value) {
        this.$emit('update:fields', value)
      }
    }
  },
  methods: {
    isEdited (index) {
      return this.editedItemsIndexes.map(item => item.key).includes(index)
    },
    handleEdit (index) {
      this.editedItemsIndexes.push({ key: index, value: { ...this.localFields[index] } })
    },
    async handleRemove (index) {
      if (!this.id) {
        this.remove(index)
      } else {
        this.showModal = true
        this.indexToDelete = index
      }
    },
    handleSubmit (item, index) {
      this.$emit('edit', { item, index })
      const itemIndex = this.editedItemsIndexes.map(item => item.key).indexOf(index)
      itemIndex !== -1 && this.editedItemsIndexes.splice(itemIndex, 1)
    },
    handleCancel (itemIndex) {
      const index = this.editedItemsIndexes.map(item => item.key).indexOf(itemIndex)
      if (index !== -1) {
        this.localFields[itemIndex] = this.editedItemsIndexes[index].value
        this.editedItemsIndexes.splice(index, 1)
      }
    },
    closeModal () {
      this.showModal = false
      this.articleList = []
      this.indexToDelete = null
    },
    remove () {
      this.$emit('remove', this.indexToDelete)
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.field-table {
  .field-table__head, .field-table__body__row {
    align-items: center;
    display: grid;
    gap: rem(30px);
    grid-template-columns: 2fr 2fr 2fr 1fr 3fr 2fr;
  }

  &__head {
    margin-bottom: rem(16px);

    &__title {
      @include font(400 14px "Roboto");
    }
  }

  &__body {
    display: grid;
    gap: rem(8px);

    &__row {
      &__title {
        @include font(700 14px "Roboto");
        overflow: hidden;
      }

      &__actions {
        display: flex;
        gap: rem(4px);
      }
    }
  }
}
</style>
