<template>
  <div class="row">
    <Input
      id="embedded_application_field_title"
      v-model="field.displayName"
      :label="$t('embeddedApplication.field.title')"
      class="col-lg-2"
      @blur="$v.field.displayName.$touch()"
      :error="$v.field.displayName.$error"
      @input="createFieldTitleSlug"
      required
    />

    <Input
      id="embedded_application_field_title_cp"
      v-model="field.identifier"
      :label="$t('embeddedApplication.field.titleCP')"
      class="col-lg-2"
      required
    />

    <Select
      id="embedded_application_field_type"
      v-model="field.type"
      :options="typeOptions"
      :label="$t('embeddedApplication.field.type')"
      :error="$v.field.type.$error"
      class="col-lg-2"
      required
    />

    <Select
      id="embedded_application_field_required"
      v-model="field.isRequired"
      :options="requiredOptions"
      :label="$t('embeddedApplication.field.required')"
      :error="$v.field.isRequired.$error"
      class="col-lg-1"
      required
    />

    <Input
      id="embedded_application_field_colorbox_url"
      v-model="field.colorboxUrl"
      :label="$t('embeddedApplication.field.colorboxUrl')"
      class="col-lg-3"
    />

    <div class="col-lg-2 d-flex align-items-center">
      <button class="btn btn-info flex-fill" @click="addField">{{ $t('embeddedApplication.field.add') }}</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input.vue'
import Select from '@/components/form/select/Select.vue'
import { requiredOptions, typeOptions } from '@/model/EmbeddedApplicationFieldModel'
import { slugify } from '@/helpers/string'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'EmbeddedApplicationField',
  components: {
    Select,
    Input
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    typeOptions () {
      return typeOptions
    },
    requiredOptions () {
      return requiredOptions.map(item => ({ ...item, title: this.$t(item.title) }))
    }
  },
  methods: {
    addField () {
      this.$v.field.$touch()
      if (this.$v.field.$error) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$emit('add-field', this.field)
      this.$v.field.$reset()
    },
    createFieldTitleSlug (value) {
      this.field.identifier = slugify(value)
    }
  },
  validations () {
    return {
      field: {
        displayName: {
          required
        },
        type: {
          required,
          nonZero: value => value !== 0
        },
        isRequired: {
          required,
          nonZero: value => value !== 0
        }
      }
    }
  }
}
</script>
