<template>
  <ModuleForm
    :module="embeddedApplication"
    :show-header-info="disabled"
    :module-name="$tc('moduleForm.embeddedApplication')"
  >
    <template #header-buttons>
      <ModuleFormButton
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />

      <ModuleFormButton
        v-if="embeddedApplication.id"
        icon="delete"
        :disabled="disabled"
        :required-permissions="requiredPermissions.deleteButton"
        @click="showModal = true"
      />

      <ModuleFormButton icon="close" @click="goToList"/>
    </template>

    <template #form-content>
      <Preloader v-if="callingAPI" on-top/>

      <div class="card">
        <h3 class="card-header">{{ $t('embeddedApplication.mainSettings') }}</h3>

        <div class="card-body">
          <div class="row">
            <Input
              id="embedded_application_title"
              v-model="embeddedApplication.displayName"
              class="col-lg-6"
              @blur="$v.embeddedApplication.displayName.$touch()"
              :error="$v.embeddedApplication.displayName.$error"
              :label="$t('embeddedApplication.title')"
              :disabled="disabled"
              required
            />

            <Input
              id="embedded_application_vlm_id"
              v-model="embeddedApplication.identifier"
              @blur="$v.embeddedApplication.identifier.$touch()"
              :error="$v.embeddedApplication.identifier.$error"
              :label="$t('embeddedApplication.identifier')"
              class="col-lg-6"
              :disabled="disabled"
              required
            />
          </div>

          <div class="row">
            <Multiselect
              id="embedded_application_sites"
              v-model="sites"
              class="col-lg-6 mb-3"
              :options="siteOptions"
              :label-desc="$t('embeddedApplication.sites')"
              label="title"
              track-by="id"
            />

            <Input
              id="embedded_application_webhook_url"
              v-model="embeddedApplication.webhookUrl"
              class="col-lg-6"
              :label="$t('embeddedApplication.webhookUrl')"
              :disabled="disabled"
            />
          </div>

          <div class="row">
            <div class="col-lg-6">
              <OutlinedCheckbox
                id="embedded_application_is_active"
                v-model="embeddedApplication.isActive"
                :label="$t('embeddedApplication.active')"
                :disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card" :class="{ 'error' : $v.embeddedApplication.fields.$error}">
        <h3 class="card-header">{{ $t('embeddedApplication.fieldSettings') }}</h3>

        <div class="card-body">
          <EmbeddedApplicationField :field="field" @add-field="addField"/>
        </div>

        <EmbeddedApplicationFieldTable
          v-if="embeddedApplication.fields.length"
          :fields="embeddedApplication.fields"
          :id="embeddedApplication.id"
          class="p-3"
          :key="updateTableKey"
          @edit="editField"
          @remove="removeField"
        />
      </div>

      <ConfirmDeleteModal
        v-if="showModal"
        :embedded-application-id="embeddedApplication.id"
        enable-delete-used
        @close="showModal = false"
        @submit="confirmDelete"
      />
    </template>
  </ModuleForm>
</template>

<script>
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import PermissionService from '@/services/PermissionService'
import Preloader from '@/components/preloader/Preloader.vue'
import { mapState } from 'vuex'
import EmbeddedApplicationModel from '@/model/EmbeddedApplicationModel'
import { minLength, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input.vue'
import Multiselect from '@/components/form/select/MultiSelect.vue'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox.vue'
import EmbeddedApplicationFieldTable from '@/components/table/EmbeddedApplicationFieldTable'
import NotifyService from '@/services/NotifyService'
import EmbeddedApplicationField from '@/components/embeddedApplication/EmbeddedApplicationField.vue'
import { create, deleteRecord, update } from '@/services/embeddedApplication/EmbeddedApplicationFieldService'
import { MODULE_MODULES_EMBEDDED_APPLICATION } from '@/model/ValueObject/UserPermissionModules'
import ConfirmDeleteModal from '@/components/embeddedApplication/ConfirmDeleteModal.vue'
import EmbeddedApplicationFieldModel from '@/model/EmbeddedApplicationFieldModel'
import { cloneDeep } from 'lodash'

export default {
  name: 'EmbeddedApplicationNewView',
  components: {
    ConfirmDeleteModal,
    EmbeddedApplicationField,
    EmbeddedApplicationFieldTable,
    OutlinedCheckbox,
    Multiselect,
    Input,
    Preloader,
    ModuleForm,
    ModuleFormButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      embeddedApplication: this._.cloneDeep(EmbeddedApplicationModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.EMBEDDED_APPLICATION_PERMISSIONS,
      field: cloneDeep(EmbeddedApplicationFieldModel),
      fieldsToDelete: [],
      showModal: false,
      updateTableKey: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    sites: {
      get () {
        return this.siteOptions.filter(site => this.embeddedApplication.selectedSiteIds.includes(site.id))
      },
      set (value) {
        this.embeddedApplication.selectedSiteIds = value.map(site => site.id)
      }
    },
    siteOptions () {
      return this.$store.getters['site/enabledSites'](MODULE_MODULES_EMBEDDED_APPLICATION)
    }
  },
  methods: {
    async getEmbeddedApplication () {
      await this.$store.dispatch('embeddedApplication/fetchOne', this.$route.params.id)
      this.embeddedApplication = this.$store.getters['embeddedApplication/detail']
    },
    goToEdit () {
      this.$router.push('/embedded-application/' + this.embeddedApplication.id + '/edit')
    },
    goToList () {
      this.$router.push('/embedded-application/')
    },
    async save () {
      this.$v.embeddedApplication.$touch()
      if (this.$v.embeddedApplication.$error) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const dispatchType = this.embeddedApplication.id ? 'embeddedApplication/update' : 'embeddedApplication/create'
      const message = this.embeddedApplication.id ? this.$t('notify.record_was_updated') : this.$t('notify.record_was_created')

      const result = await this.$store.dispatch(dispatchType, this.embeddedApplication)
      await this.createFields(result.id)
      await this.removeFields()

      await this.$store.dispatch('embeddedApplication/fetchOne', result.id)
      this.embeddedApplication = this.$store.getters['embeddedApplication/detail']
      this.updateTableKey = Date.now()

      const error = this.$store.getters['embeddedApplication/error']
      if (error) {
        NotifyService.setErrorMessage(error)
      } else {
        NotifyService.setSuccessMessage(message)
      }
      this.goToEdit()
    },
    addField (item) {
      const found = this.embeddedApplication.fields.find(field => field.identifier === item.identifier)
      if (found) {
        NotifyService.setErrorMessage(this.$t('embeddedApplication.field_already_exists'))
        return
      }
      this.embeddedApplication.fields.push(item)
      this.field = cloneDeep(EmbeddedApplicationFieldModel)
    },
    editField ({ item, index }) {
      this.embeddedApplication.fields[index] = item
    },
    async removeField (index) {
      const item = this.embeddedApplication.fields[index]
      if (item.id) {
        this.fieldsToDelete.push(item.id)
      }
      this.embeddedApplication.fields.splice(index, 1)
    },
    async createFields (embeddedApplicationId) {
      for (const field of this.embeddedApplication.fields) {
        if (field.id) {
          await update({ ...field, embeddedApplicationId })
        } else {
          await create({ ...field, embeddedApplicationId })
        }
      }
    },
    async removeFields () {
      if (this.embeddedApplication.fields.length === 0) {
        NotifyService.setErrorMessage(this.$t('embeddedApplication.application_must_have_at_least_one_field'))
        return
      }
      for (const fieldId of this.fieldsToDelete) {
        await deleteRecord(fieldId)
      }
      this.fieldsToDelete = []
    },
    async confirmDelete () {
      await this.$store.dispatch('embeddedApplication/deleteRecord', this.embeddedApplication)
      await this.$store.dispatch('embeddedApplication/fetch')
      this.goToList()
    }
  },
  validations () {
    return {
      embeddedApplication: {
        displayName: {
          required
        },
        identifier: {
          required
        },
        fields: {
          required,
          minLength: minLength(1)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  &-header {
    color: #979797;
    margin: rem(8px) 0;
  }

  &.error {
    border: 1px solid red;
  }
}
</style>
